import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import { StaticImage } from "gatsby-plugin-image"
import Therapist from "../../../components/Therapist/Therapist"

// markup

const name = 'Christine Ellis'
const license = 'MSW, LCSW-PIP'
const areas = [
    'Anxiety',
    'Depression',
    'PTSD',
    'Oppositional Defiant Disorder',
    'Sexual Orientation',
    'Gender Identity',
    'Trauma',
    'Abuse',
    'Grief and Loss',
    'ADD/ADHD',
    'Blended Family Issues',
    'Divorce',
]


const IndexPage = () =>
(
    <Layout className="therapists">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./christine-ellisv2.jpg' alt={name} layout="constrained" className="shadow" />)}
        >
            <p>
                Christine earned her degree in social work in 1994. She began her career working in residential treatment facilities for youths ages 10 to 18. After receiving a master's degree in social work in 2000, she began doing home-based therapy through the community mental health center. The Department of Social Services, Department of Corrections, Court Services, schools and other health care providers referred clients ages 4 to 18 to her. In 2006, Christine worked as a hospice social worker where she facilitated grief support groups and provided care to individuals of all ages at the end of life.
            </p>

            <p>
                She began working in private practice with Integrative Wellness in 2007. She works with families, adolescents and adults. Her experience is helping those who struggle with anxiety, depression, PTSD, oppositional defiant disorder, sexual orientation, gender identity, trauma, abuse, grief and loss, ADD/ADHD, blended family issues and divorce.
            </p>

            <p>
                Christine is a life-long resident of Sioux Falls. In her free time, she enjoys long-distance running, camping, cooking and spending time with her family and pets.
            </p>
        </Therapist>
    </Layout>
)

export default IndexPage
